import { TscDetails } from '../types';
import ax from './axios.config';
import { toCamelCase } from '../lib';

interface ApiResponse {
  nft: TscDetails;
  image: string;
}

export const getTSCDetails = async (tokenId: string | number) => {
  const res = await ax.get<ApiResponse>(`nft/${tokenId}`);
  return toCamelCase(res.data) as ApiResponse;
};

const tscAPI = {
  getTSCDetails,
};

export default tscAPI;
