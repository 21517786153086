import { TextField } from '@mui/material';
import TextColorPicker from './ColorPicker';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedCustomText } from '../redux/slices/userInputSlice';

const CaptionInput = () => {
  const { selectedCustomText, isGeneratingTscImage } = useAppSelector((state) => state.userInput);
  const dispatch = useAppDispatch();

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedCustomText({ ...selectedCustomText, content: e.target.value }));
  };

  const handleColorChange = (color: string) => {
    dispatch(setSelectedCustomText({ ...selectedCustomText, color }));
  };

  return (
    <TextField
      fullWidth
      label="Custom Caption"
      variant="outlined"
      placeholder="Twitter Handle / Discord ID / etc."
      defaultValue={selectedCustomText.content}
      color="primary"
      onChange={handleTextChange}
      disabled={isGeneratingTscImage}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <TextColorPicker inputCustomText={selectedCustomText} setTextColor={handleColorChange} />
        ),
      }}
    />
  );
};

export default CaptionInput;
