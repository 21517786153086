// Logo Overlays
import LogoBlack from '../assets/logo-overlays/logo-black.png';
import LogoWhite from '../assets/logo-overlays/logo-white.png';

// Background images
import BGBlur from '../assets/backgrounds/blur.jpg';
import BGBubbles from '../assets/backgrounds/Bubbles.jpg';
import BGDrippedSlime from '../assets/backgrounds/DrippedSlime.jpg';
import BGFire from '../assets/backgrounds/Fire.jpg';
import BGFumes from '../assets/backgrounds/Fumes.jpg';
import BGLava from '../assets/backgrounds/Lava.jpg';
import BGOmbre from '../assets/backgrounds/Ombre.jpg';
import BGRainbowFire from '../assets/backgrounds/Rainbowfire.jpg';
import BGRainbowSwirl from '../assets/backgrounds/Rainbowswirl.jpg';
import BGRainbowWaves from '../assets/backgrounds/Rainbowwaves.jpg';
import BGRiverBreak from '../assets/backgrounds/RiverBreak.jpg';
import BGSlimemountain from '../assets/backgrounds/Slimemountain.jpg';
import BGSmoke from '../assets/backgrounds/Smoke.jpg';
import BGSparkles from '../assets/backgrounds/Sparkles.jpg';
import BGTrippy from '../assets/backgrounds/Trippy.jpg';

// Legendary
import BGCalavera from '../assets/backgrounds/Calavera.jpg';
import BGCaptainSkull from '../assets/backgrounds/Captain skulls.jpg';
import BGFireDemon from '../assets/backgrounds/Fire demon.jpg';
import BGGenius from '../assets/backgrounds/Genius.jpg';
import BGLoneWolf from '../assets/backgrounds/Lone wolf.jpg';
import BGNemesis from '../assets/backgrounds/Nemesis.jpg';
import BGRideOrDie from '../assets/backgrounds/Ride or die.jpg';
import BGSoluSucka from '../assets/backgrounds/Soul sucka.jpg';
import BGTSCProgram from '../assets/backgrounds/TSC program.jpg';
import BGWarlock from '../assets/backgrounds/Warlock.jpg';

// Pre toxic
import PTBlur from '../assets/backgrounds/toxic/Blur.png';
import PTBubbles from '../assets/backgrounds/toxic/Bubbles.png';
import PTDrippedSlime from '../assets/backgrounds/toxic/Dripped slime.png';
import PTFire from '../assets/backgrounds/toxic/Fire.png';
import PTFumes from '../assets/backgrounds/toxic/Fumes.png';
import PTLava from '../assets/backgrounds/toxic/Lava.png';
import PTMint from '../assets/backgrounds/toxic/Mint.png';
import PTOmbre from '../assets/backgrounds/toxic/Ombré.png';
import PTRainbowFire from '../assets/backgrounds/toxic/Rainbow fire.png';
import PTRainbowSwirl from '../assets/backgrounds/toxic/Rainbow swirl.png';
import PTRainbowWaves from '../assets/backgrounds/toxic/Rainbow waves.png';
import PTRiverbreak from '../assets/backgrounds/toxic/River break.png';
import PTSlimeMountain from '../assets/backgrounds/toxic/Slime mountain.png';
import PTSmoke from '../assets/backgrounds/toxic/Smoke.png';
import PTSparkle from '../assets/backgrounds/toxic/Sparkle.png';
import PTTrippy from '../assets/backgrounds/toxic/Trippy.png';

export const getBackgroundColor = (
  background: string | undefined,
  preToxic: boolean
): { color: string; image: string | null } => {
  switch (background) {
    case 'Cyan':
      return { color: preToxic ? '#00addc' : '#9DE3FF', image: null };
    case 'Orange':
      return { color: preToxic ? '#ffc09c' : '#FFAC4B', image: null };
    case 'Mint':
      return { color: '#B9FDDD', image: preToxic ? PTMint : null };
    case 'Rainbow Waves':
      return { color: '#FBC09C', image: preToxic ? PTRainbowWaves : BGRainbowWaves };
    case 'Fire':
      return { color: '#FBFEC4', image: preToxic ? PTFire : BGFire };
    case 'Chartreuse':
      return { color: preToxic ? '#e7ff38' : '#E3E623', image: null };
    case 'Blur':
      return { color: '#FB92E8', image: preToxic ? PTBlur : BGBlur };
    case 'Lava':
      return { color: '#FBC09C', image: preToxic ? PTLava : BGLava };
    case 'Smoke':
      return { color: '#C58BFF', image: preToxic ? PTSmoke : BGSmoke };
    case 'Bloom':
      return { color: preToxic ? '#fe90e9' : '#FBB5EE', image: null };
    case 'Ombre':
      return { color: '#FA8C6A', image: preToxic ? PTOmbre : BGOmbre };
    case 'Slime Mountain':
      return { color: '#F1F0DA', image: preToxic ? PTSlimeMountain : BGSlimemountain };
    case 'Bubbles':
      return { color: '#82FDC3', image: preToxic ? PTBubbles : BGBubbles };
    case 'River Break':
      return { color: '#9BE3FF', image: preToxic ? PTRiverbreak : BGRiverBreak };
    case 'Rainbow Fire':
      return { color: '#CDBAF2', image: preToxic ? PTRainbowFire : BGRainbowFire };
    case 'Fumes':
      return { color: '#F7C9D2', image: preToxic ? PTFumes : BGFumes };
    case 'Dripped Slime':
      return { color: '#F1F0DA', image: preToxic ? PTDrippedSlime : BGDrippedSlime };
    case 'Trippy':
      return { color: '#D3FAFB', image: preToxic ? PTTrippy : BGTrippy };
    case 'Sparkles':
      return { color: '#3FEAD9', image: preToxic ? PTSparkle : BGSparkles };
    case 'Rainbow Swirl':
      return { color: '#FB90E9', image: preToxic ? PTRainbowSwirl : BGRainbowSwirl };
    case 'Calavera':
      return { color: '#444', image: BGCalavera };
    case 'Captain Skulls':
      return { color: '#444', image: BGCaptainSkull };
    case 'Fire Demon':
      return { color: '#444', image: BGFireDemon };
    case 'Genius':
      return { color: '#444', image: BGGenius };
    case 'Lone Wolf':
      return { color: '#444', image: BGLoneWolf };
    case 'Nemesis':
      return { color: '#444', image: BGNemesis };
    case 'Ride or Die':
      return { color: '#444', image: BGRideOrDie };
    case 'Soul Sucka':
      return { color: '#444', image: BGSoluSucka };
    case 'TSC Program':
      return { color: '#444', image: BGTSCProgram };
    case 'Warlock':
      return { color: '#444', image: BGWarlock };
    default:
      return { color: '#444', image: null };
  }
};

export const getLogoOverlay = (logoOverlay: string) => {
  switch (logoOverlay) {
    case 'black':
      return LogoBlack;
    case 'white':
      return LogoWhite;
    default:
      return 'none';
  }
};

export const toDataURL = (url: string, callback: (result: string | ArrayBuffer | null) => void) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};
