import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBar from '../components/NavBar';
import InputForm from '../components/InputForm';
import ImagePreview from '../components/ImagePreview';
import LockScreenToggle from '../components/LockscreenToggle';
import { useAppSelector } from '../redux/hooks';
import ManageForm from '../components/ManageForm';
import Footer from '../components/Footer';

const Main = () => {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:990px)');
  const { value } = useAppSelector((state) => state.tscDetails);

  return (
    <Box
      minHeight="650px"
      sx={{
        height: {
          md: 'calc(100vh - 64px - 64px - 16px)',
          xs: 'fit-content',
        },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <NavBar />

      <Box
        display="flex"
        height="100%"
        mt="64px"
        flexDirection={matches ? 'row' : 'column'}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            mr: {
              md: 5,
              xs: 0,
            },
            mt: {
              xs: 3,
              md: 0,
            },
          }}
        >
          <Box
            sx={{
              width: {
                md: '390px',
                xs: '100%',
              },
              borderRadius: 3,
              height: 'fit-content',
              border: `2px solid ${theme.palette.primary.dark}`,
            }}
          >
            {value?.tokenId ? <ManageForm /> : <InputForm />}
          </Box>
        </Box>
        <Box
          height="100%"
          sx={{
            marginRight: {
              xs: 0,
              md: 5,
            },
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{
            marginTop: {
              xs: 5,
              md: 0,
            },
          }}
        >
          <ImagePreview />
          <LockScreenToggle />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Main;
