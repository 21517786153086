import { combineReducers } from '@reduxjs/toolkit';
import tscDetailsReducer from './slices/tscDetailsSlice';
import userInputReducer from './slices/userInputSlice';

const rootReducer = combineReducers({
  // Put reducers here
  tscDetails: tscDetailsReducer,
  userInput: userInputReducer,
});

export default rootReducer;
