import { AppBar, Box, Button, Toolbar } from '@mui/material';
import TscLogo from '../assets/logo.png';

const NavBar = () => {
  return (
    <AppBar position="fixed" sx={{ background: '#000' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={TscLogo} alt="Tsc" height="40px" />
        </Box>
        <Box>
          <Button
            variant="outlined"
            href="https://twitter.com/toxicskullsclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow TSC
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
