import { Switch, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setShowLockscreenOverlay } from '../redux/slices/userInputSlice';

const LockScreenToggle = () => {
  const dispatch = useAppDispatch();
  const { showLockscreenOverlay } = useAppSelector((state) => state.userInput);

  const handleChange = (val: boolean) => {
    dispatch(setShowLockscreenOverlay(val));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
      <Typography fontWeight="700">Show Lockscreen UI:</Typography>
      <Switch
        color="secondary"
        onChange={(e) => handleChange(e.target.checked)}
        checked={showLockscreenOverlay}
      />
    </Box>
  );
};

export default LockScreenToggle;
