import { CssBaseline, responsiveFontSizes, ThemeOptions } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import Main from './pages/Main';

const App = () => {
  const common: ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: "'Kanit', sans-serif;",
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
    },
  };

  const tsc: Theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
        dark: '#444',
      },
      secondary: {
        main: '#1da1f2',
      },
      background: {
        default: '#000',
        paper: '#141414',
      },
      text: {
        primary: '#ffffff',
        secondary: '#444',
      },
    },
    ...common,
  });

  return (
    <ThemeProvider theme={responsiveFontSizes(tsc)}>
      <CssBaseline />
      <Main />
    </ThemeProvider>
  );
};

export default App;
