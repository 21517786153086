import { Box, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';

const Footer = () => {
  return (
    <Box
      py={2}
      height="64px"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{
        display: {
          md: 'flex',
          xs: 'block',
        },
        position: 'relative',
      }}
    >
      <Stack direction="row" gap={0.5} justifyContent="center">
        <Typography>Powered by</Typography>

        <Link href="https://twitter.com/tokenwallpaper" target="_blank" rel="noreferrer">
          Token Wallpaper
        </Link>
      </Stack>
    </Box>
  );
};

export default Footer;
