import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FlashlightOn, CameraAlt } from '@mui/icons-material';
import { format } from 'date-fns';
import { useAppSelector } from '../redux/hooks';
import { getLogoOverlay } from '../lib';
import { useDebouncedEffect } from '../hooks/useDebounceEffect';

const ImagePreview = () => {
  const [date, setDate] = useState(new Date());
  const {
    showLockscreenOverlay,
    isGeneratingTscImage,
    imageScale,
    logoPosition,
    logoScale,
    selectedLogoOverlay,
    selectedCustomText,
    captionScale,
    captionPosition,
  } = useAppSelector((state) => state.userInput);
  const { status, value, bgColor, bgImage, imageData } = useAppSelector(
    (state) => state.tscDetails
  );
  // const dispatch = useAppDispatch();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const logo = useMemo(() => {
    return getLogoOverlay(selectedLogoOverlay);
  }, [selectedLogoOverlay]);

  const tick = () => {
    setDate(new Date());
  };

  // const getImageData = () => {
  //   if (value?.dna) {
  //     // Image is loading
  //     dispatch(setIsGeneratingTscImage(true));
  //     toDataURL(`https://gateway.ipfs.io/ipfs/${value.dna}`, (res) => {
  //       // Image is loaded
  //       dispatch(setIsGeneratingTscImage(false));
  //       dispatch(setImageData(res));
  //     });
  //   }
  // };

  const drawImage = () => {
    if (!canvasRef.current!) return;
    const context = canvasRef.current.getContext('2d');
    const canvas = canvasRef.current;

    if (context && canvas) {
      // Clear canvas
      context.clearRect(0, 0, canvas.width, canvas.height);
      const { width, height } = canvas;

      if (bgColor !== '#444') {
        context.fillStyle = bgColor;
        context.fillRect(0, 0, canvas.width, canvas.height);
      }

      const drawNFT = () => {
        if (imageData) {
          const image = new Image();
          image.src = imageData as string;

          image.onload = () => {
            const imgWidth = width * (imageScale / 100);
            const imgHeight = width * (imageScale / 100);
            const x = width / 2 - imgWidth / 2;
            const y = height - imgHeight;
            context.drawImage(image, x, y, imgWidth, imgHeight);

            if (logo) {
              const logoOverlay = new Image();
              logoOverlay.src = logo;

              logoOverlay.onload = () => {
                const imgWidth = (width / 3) * (logoScale / 100);
                const imgHeight = (width / 3) * (logoScale / 100);
                const x = width / 2 - imgWidth / 2;
                const y = height / 2 - imgHeight / 2 - 200;
                let newY = (y * logoPosition) / 100;
                context.drawImage(logoOverlay, x, newY, imgWidth, imgHeight);
              };
            }

            if (selectedCustomText.content) {
              const { color, content } = selectedCustomText;
              const capScale = (100 * captionScale) / 100;
              context.font = `${capScale}px 'Jost*'`;
              context.fillStyle = color;
              context.textAlign = 'center';
              const x = width / 2;
              const y = (height / 2 + 70) * (captionPosition / 100);
              context.fillText(content, x, y);
            }
          };
        }
      };

      if (bgImage) {
        const bg = new Image();
        bg.src = bgImage as string;
        bg.onload = () => {
          const bgWidth = width * (imageScale / 100);
          const bgHeight = height * (imageScale / 100);
          const x = width / 2 - bgWidth / 2;
          const y = height - bgHeight;
          context.drawImage(bg, x, y, bgWidth, bgHeight);
          drawNFT();
        };
      } else {
        drawNFT();
      }
    }
  };

  const statusMessage = useMemo(() => {
    if (status === 'pending') {
      return 'Loading...';
    } else if (isGeneratingTscImage) {
      return 'Fetching image from IPFS...';
    } else if (!value?.tokenId) {
      return (
        <>
          Please enter your <br /> TSC ID
        </>
      );
    }
  }, [value, status, isGeneratingTscImage]);

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  useDebouncedEffect(
    () => {
      drawImage();
      // eslint-disable-next-line
    },
    [
      imageData,
      logo,
      imageScale,
      logoPosition,
      logoScale,
      selectedCustomText,
      captionPosition,
      captionScale,
    ],
    400
  );

  // useEffect(() => {
  //   getImageData();
  //   // eslint-disable-next-line
  // }, [value?.image]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width="100%"
        height="100%"
        position="relative"
        overflow="hidden"
        border={`2px solid ${bgColor}`}
        sx={{
          transition: 'border-color .2s ease-in-out;',
          padding: {
            xs: '2.50vw',
            md: '1.25vmin',
          },
          borderRadius: {
            xs: '9.8vw',
            md: '5.5vmin',
          },
        }}
      >
        <Box
          width="100%"
          height="calc(85vmin - 150px)"
          overflow="hidden"
          position="relative"
          sx={{
            aspectRatio: '.46/1',
            maskImage: 'url(/img/mask.png)',
            WebkitMaskImage: 'url(/img/mask.png)',
            maskRepeat: 'no-repeat',
            maskSize: '100% 100%',
            WebkitMaskSize: '100% 100%',
            backgroundColor: '#1a1a1a',
            height: {
              xs: '125vw',
              md: 'calc(85vmin - 150px)',
            },
          }}
        >
          <canvas
            ref={canvasRef}
            id="canvas"
            width="1170"
            height="2532"
            style={{
              // backgroundColor: bgColor === '#444' ? 'unset' : bgColor,
              position: 'absolute',
              width: '100%',
              height: '100%',
              opacity: 1,
              transition: 'opacity .2s ease-in-out',
            }}
          />

          <Typography
            px={5}
            fontSize="2.5vmin"
            fontWeight="bold"
            position="absolute"
            width="100%"
            textAlign="center"
            sx={{ top: '35%' }}
          >
            {statusMessage}
          </Typography>

          {!value?.tokenId && (
            <Box>
              <img
                src="/img/tsc-silhouette.png"
                alt="TSC Silhouette"
                style={{
                  position: 'absolute',
                  margin: '0 auto',
                  maxWidth: '30vmin',
                  maxHeight: '30vmin',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: -1,
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              height: '100%',
              width: '100%',
              transition: 'transform .2s ease-in-out',
              transform: showLockscreenOverlay ? 'translateY(0)' : 'translateY(-100%)',
            }}
          >
            <img
              src="/img/phone-lockscreen.png"
              alt="LockScreen"
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: 0,
                height: 0,
                minWidth: '100%',
                maxWidth: '100%',
                minHeight: '100%',
                maxHeight: '100%',
              }}
            />

            <Box
              width="12%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                aspectRatio: '1/1',
                borderRadius: '50%',
                position: 'absolute',
                bottom: '6.4%',
                left: '12.5%',
                backgroundColor: 'rgba(0,0,0,.2)',
                backdropFilter: 'blur(32px)',
              }}
            >
              <FlashlightOn />
            </Box>
            <Box
              width="12%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                aspectRatio: '1/1',
                borderRadius: '50%',
                position: 'absolute',
                bottom: '6.4%',
                right: '12.5%',
                backgroundColor: 'rgba(0,0,0,.2)',
                backdropFilter: 'blur(32px)',
              }}
            >
              <CameraAlt />
            </Box>

            <Typography
              textAlign="center"
              left="0"
              right="0"
              position="absolute"
              top="10%"
              fontWeight="300"
              sx={{
                fontSize: {
                  xs: '12vw',
                  md: '7.5vmin',
                },
              }}
            >
              {format(date, 'h:mm')}
            </Typography>
            <Typography
              textAlign="center"
              left="0"
              right="0"
              position="absolute"
              top="23%"
              fontWeight="400"
              sx={{
                fontSize: {
                  xs: '2.7vw',
                  md: '1.8vmin',
                },
              }}
            >
              {format(date, 'cccc, MMMM d')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImagePreview;
