import { Box, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getTscDetailsAsync } from '../redux/slices/tscDetailsSlice';

type FormData = {
  tscId: string;
};

const InputForm = () => {
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const { status } = useAppSelector((state) => state.tscDetails);
  const dispatch = useAppDispatch();

  const handleFormSubmit = (data: FormData) => {
    // Fetch nft metadata from server
    dispatch(getTscDetailsAsync(data.tscId))
      .unwrap()
      .catch((error) => {
        setError('tscId', { message: error?.message });
      });
    reset();
  };

  return (
    <Box sx={{ paddingX: 2, paddingY: 1 }}>
      <Box sx={{ borderBottom: '1px dashed #444', marginBottom: 3 }}>
        <Typography fontSize={20} textAlign="center" fontWeight="bold" sx={{ padding: '10px' }}>
          Toxic Skulls Club Wallpapers
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            fullWidth
            label="TSC ID"
            variant="outlined"
            error={!!errors?.tscId}
            helperText={errors?.tscId?.message ?? ''}
            sx={{
              mb: 3,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            {...register('tscId', { required: 'Please enter a TSC ID' })}
          />

          <LoadingButton
            type="submit"
            loading={status === 'pending'}
            variant="outlined"
            sx={{
              width: '100%',
              mb: 3,
              py: 1.5,
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            Preview
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default InputForm;
