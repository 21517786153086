import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
  Slider,
  SelectChangeEvent,
  OutlinedInput,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { resetState } from '../redux/slices/tscDetailsSlice';
import {
  resetInputState,
  setCaptionPosition,
  setCaptionScale,
  setImageScale,
  setLogoPosition,
  setLogoScale,
  setSelectedLogoOverlay,
} from '../redux/slices/userInputSlice';
import CaptionInput from './CaptionInput';

const ManageForm = () => {
  const { status, value } = useAppSelector((state) => state.tscDetails);
  const {
    imageScale,
    logoPosition,
    logoScale,
    selectedLogoOverlay,
    selectedCustomText,
    captionScale,
    captionPosition,
    isGeneratingTscImage,
  } = useAppSelector((state) => state.userInput);

  const dispatch = useAppDispatch();

  const handleBack = () => {
    dispatch(resetState());
    dispatch(resetInputState());
  };

  const handleImageScale = (_: Event, val: number | number[]) => {
    dispatch(setImageScale(+val));
  };

  const handleLogoChange = (e: SelectChangeEvent) => {
    dispatch(setSelectedLogoOverlay(e.target.value));
  };

  const handleLogoScale = (_: Event, val: number | number[]) => {
    dispatch(setLogoScale(+val));
  };

  const handleLogoPosition = (_: Event, val: number | number[]) => {
    dispatch(setLogoPosition(+val));
  };

  const handleCaptionScale = (_: Event, val: number | number[]) => {
    dispatch(setCaptionScale(+val));
  };

  const handleCaptionPosition = (_: Event, val: number | number[]) => {
    dispatch(setCaptionPosition(+val));
  };

  const handleDownload = () => {
    var canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (canvas) {
      var url = canvas.toDataURL('image/png');
      var link = document.createElement('a');
      link.download = `${value?.name}.png`;
      link.href = url;
      link.click();
    }
  };

  return (
    <Box sx={{ paddingX: 2, paddingY: 1 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ borderBottom: '1px dashed #444', marginBottom: 3 }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={handleBack}
          sx={{ color: 'secondary.main', fontWeight: 'bold' }}
        >
          Back
        </Button>
        <Typography fontSize={20} textAlign="center" fontWeight="bold" sx={{ padding: '10px' }}>
          {value?.name}
        </Typography>
      </Box>

      <Stack direction="row" sx={{ mb: 2 }} justifyContent="space-between" alignItems="center">
        <Typography fontWeight="bold">Image Scale:</Typography>
        <Box display="flex" justifyContent="center">
          <Slider
            aria-label="Scale"
            min={100}
            max={180}
            size="small"
            step={5}
            sx={{ width: 140, mr: 2 }}
            onChange={handleImageScale}
            value={imageScale}
            disabled={isGeneratingTscImage}
          />
          <Typography textAlign="right" width={30}>
            {imageScale}%
          </Typography>
        </Box>
      </Stack>

      <Stack spacing={3} my={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="logo" shrink>
            Logo
          </InputLabel>
          <Select
            labelId="logo"
            id="logo-select"
            label="Logo"
            input={<OutlinedInput notched label="Logo" />}
            value={selectedLogoOverlay}
            onChange={handleLogoChange}
            disabled={isGeneratingTscImage}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="white">TSC - White</MenuItem>
            <MenuItem value="black">TSC - Black</MenuItem>
          </Select>
        </FormControl>

        {selectedLogoOverlay && (
          <>
            <Stack
              direction="row"
              sx={{ mb: 2 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">Logo Scale:</Typography>
              <Box display="flex" justifyContent="center">
                <Slider
                  aria-label="Scale"
                  min={50}
                  max={150}
                  size="small"
                  step={5}
                  sx={{ width: 140, mr: 2 }}
                  onChange={handleLogoScale}
                  value={logoScale}
                  disabled={isGeneratingTscImage}
                />
                <Typography textAlign="right" width={30}>
                  {logoScale}%
                </Typography>
              </Box>
            </Stack>

            <Stack
              direction="row"
              sx={{ mb: 2 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">Logo Position:</Typography>
              <Box display="flex" justifyContent="center">
                <Slider
                  aria-label="Scale"
                  min={50}
                  max={150}
                  size="small"
                  step={1}
                  sx={{ width: 140, mr: 2 }}
                  onChange={handleLogoPosition}
                  value={logoPosition}
                  disabled={isGeneratingTscImage}
                />
                <Typography textAlign="right" width={30}>
                  {logoPosition}%
                </Typography>
              </Box>
            </Stack>
          </>
        )}

        <CaptionInput />

        {selectedCustomText.content && (
          <>
            <Stack
              direction="row"
              sx={{ mb: 2 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">Caption Scale:</Typography>
              <Box display="flex" justifyContent="center">
                <Slider
                  aria-label="Scale"
                  min={50}
                  max={150}
                  size="small"
                  step={5}
                  sx={{ width: 140, mr: 2 }}
                  onChange={handleCaptionScale}
                  value={captionScale}
                  disabled={isGeneratingTscImage}
                />
                <Typography textAlign="right" width={30}>
                  {captionScale}%
                </Typography>
              </Box>
            </Stack>

            <Stack
              direction="row"
              sx={{ mb: 2 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="bold">Caption Position:</Typography>
              <Box display="flex" justifyContent="center">
                <Slider
                  aria-label="Scale"
                  min={50}
                  max={150}
                  size="small"
                  step={1}
                  sx={{ width: 140, mr: 2 }}
                  onChange={handleCaptionPosition}
                  value={captionPosition}
                  disabled={isGeneratingTscImage}
                />
                <Typography textAlign="right" width={30}>
                  {captionPosition}%
                </Typography>
              </Box>
            </Stack>
          </>
        )}

        <LoadingButton
          type="submit"
          loading={status === 'pending'}
          disabled={isGeneratingTscImage}
          variant="outlined"
          sx={{
            width: '100%',
            mb: 3,
            py: 1.5,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
          onClick={handleDownload}
        >
          Generate Wallpaper
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ManageForm;
