import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TscDetailsState } from '../../types';
import tscAPI from '../../services/tscAPI';
import { getBackgroundColor } from '../../lib';

const initialState: TscDetailsState = {
  value: null,
  status: 'idle',
  bgColor: '#444',
  bgImage: null,
  imageData: null,
};

export const getTscDetailsAsync = createAsyncThunk('tscDetails', tscAPI.getTSCDetails);

const tscDetailsSlice = createSlice({
  name: 'tscDetails',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setImageData: (state, action: PayloadAction<string | ArrayBuffer | null>) => {
      state.imageData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTscDetailsAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getTscDetailsAsync.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.value = action.payload.nft;
        let tscBackground = action.payload?.nft?.attributes.find(
          (trait) => trait.traitType === 'Background'
        )?.value;
        const legend = action.payload?.nft?.attributes.find(
          (trait) => trait.traitType === 'Legendary'
        )?.value;
        const preToxic =
          action.payload?.nft?.attributes.find((trait) => trait.traitType === 'State')?.value ===
          'Pre-Toxic';
        if (tscBackground === undefined) {
          tscBackground = legend;
        }
        const { color, image } = getBackgroundColor(tscBackground, preToxic);
        state.bgColor = color;
        state.bgImage = image;
        state.imageData = action.payload.image;
      })
      .addCase(getTscDetailsAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      });
  },
});
export const { resetState, setImageData } = tscDetailsSlice.actions;
export default tscDetailsSlice.reducer;
