import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInputState } from '../../types';

const initialState: UserInputState = {
  showLockscreenOverlay: true,
  isGeneratingTscImage: false,
  selectedLogoOverlay: '',
  imageScale: 100,
  logoScale: 100,
  logoPosition: 100,
  captionScale: 100,
  captionPosition: 100,
  selectedCustomText: {
    content: '',
    color: '#fff',
  },
};

const userInputSlice = createSlice({
  name: 'userInput',
  initialState,
  reducers: {
    resetInputState: () => {
      return initialState;
    },
    setShowLockscreenOverlay: (
      state: UserInputState,
      action: PayloadAction<UserInputState['showLockscreenOverlay']>
    ) => {
      state.showLockscreenOverlay = action.payload;
    },
    setIsGeneratingTscImage: (
      state: UserInputState,
      action: PayloadAction<UserInputState['isGeneratingTscImage']>
    ) => {
      state.isGeneratingTscImage = action.payload;
    },
    setSelectedLogoOverlay: (
      state: UserInputState,
      action: PayloadAction<UserInputState['selectedLogoOverlay']>
    ) => {
      state.selectedLogoOverlay = action.payload;
    },
    setImageScale: (state, action: PayloadAction<number>) => {
      state.imageScale = action.payload;
    },
    setLogoScale: (state, action: PayloadAction<number>) => {
      state.logoScale = action.payload;
    },
    setLogoPosition: (state, action: PayloadAction<number>) => {
      state.logoPosition = action.payload;
    },
    setSelectedCustomText: (
      state: UserInputState,
      action: PayloadAction<UserInputState['selectedCustomText']>
    ) => {
      state.selectedCustomText = action.payload;
    },
    setCaptionScale: (state, action: PayloadAction<number>) => {
      state.captionScale = action.payload;
    },
    setCaptionPosition: (state, action: PayloadAction<number>) => {
      state.captionPosition = action.payload;
    },
  },
});

export const {
  resetInputState,
  setShowLockscreenOverlay,
  setIsGeneratingTscImage,
  setSelectedLogoOverlay,
  setImageScale,
  setLogoScale,
  setLogoPosition,
  setSelectedCustomText,
  setCaptionScale,
  setCaptionPosition,
} = userInputSlice.actions;

export default userInputSlice.reducer;
